import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IProfileLinks } from '../../../models/profilelink.model';

@Injectable()
export class LobListService {

    constructor(private http: HttpClient) { }

    getProducts(url: string, userId: string,companyId: string): Observable< any> {
        return this.http.post<any>(`${url}/api/user/GetEligibleProductsForCustomer?userId=${userId}&companyId=${companyId}`, {})
        .pipe (
            catchError(this.handleError)
            );
    }
    getDocumentDetails(url: string, presignedurlRequest: any): Observable< any> {
        return this.http.post<any>(`${url}/api/document/getPresignedurl`, presignedurlRequest)
        .pipe (
            catchError(this.handleError)
            );
    }

    downloadFile(url: string): Observable<HttpEvent<any>> {
        return this.http.get(`${url}`, { responseType: 'blob', reportProgress: true,
        observe: 'events', headers: { accept: 'application/octet-stream' }});
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
}
