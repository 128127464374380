export interface IProduct {
    id: number;
    title: string;
    url: string;
    isEligible: boolean;
    appCtxt:string;
}

export interface IUserProfileLink {
    url: string;
    linkFor: string;
    code: string;
}

export interface ICategoryAndProducts {
    id: number;
    title: string;
    products: IProduct[];
}

export interface IPanel {
    products: IProduct[];
}

export class MenuResponse {
    categories: ICategoryAndProducts[];
    products: IProduct[];
    userProfileLinks: IUserProfileLink[] = [];
    userProducts: IProduct[];
}