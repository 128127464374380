import { Component, OnInit } from '@angular/core';
import { FlatFileService } from './flat-file-service';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-flat-file',
  templateUrl: './flat-file-component.html',
  styleUrls: ['./flat-file-component.css']
})
export class FlatFileComponent implements OnInit {

  errorMessage: string;
  s3FileDetails : any;
  progress = 0;

  constructor(private flatFileService : FlatFileService) { }

  ngOnInit(): void {
    let data = JSON.parse(localStorage.getItem('s3fileDetails'));
    if(data) {
      this.s3FileDetails = data;
    }
  }

  downloadFile(data : any) {
    this.flatFileService.downloadFile(data.DownloadUrl).subscribe(event => {
      if (event.type === HttpEventType.DownloadProgress) {
        // This is an Download progress event. Compute and show the % done:
        this.progress = Math.round(100 * event.loaded / event.total);

      } else if (event instanceof HttpResponse) {
        if (window.navigator.msSaveOrOpenBlob) { // for IE and Edge
          window.navigator.msSaveBlob(event['body'], data.FileName);
        } else {
          const url = window.URL.createObjectURL(event['body']);
          const a = document.createElement('a');
          a.setAttribute('type', 'hidden');
          a.href = url;
          a.download = data.FileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          this.progress = 0;
        }
      }
    },
    (error) => {
      this.errorMessage = 'An error has ocurred. Please reload the page and try downloading again.';
    });
  }

}
