import { Component } from '@angular/core';
import { AppService } from '../../app.service';
import { LABEL_LIST } from '../../shared/constants/app.constants'

@Component({
    selector: 'unauthorize',
    templateUrl: './unauthorize.component.html'
  })
  
  export class UnauthorizeComponent {
    productTitle = LABEL_LIST.productTitle;
    customerSuportUrl: string;

    constructor(private appService: AppService) {
        this.appService.getConfigStatus().subscribe(
            _configStatus => {
              if(_configStatus) {
                let _appConfig = this.appService.getGlobalConfig();
                this.customerSuportUrl = _appConfig.veriskCustomerSupport;
              }
        });
    }
  }